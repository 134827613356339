import React from 'react';
import styled from 'styled-components';
import kosten from '../assets/images/bijkomenekosten.jpg';
import Layout from '../components/Layout';

const BodyStyles = styled.div`
  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .grid-container-tarieven {
    background-color: #e8f0fb;
    height: 630px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 500px);
    grid-gap: 0px 50px;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }

  .grid-container-kosten {
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 500px);
    grid-gap: 0px 50px;
    align-items: center;
    height: 430px;
    justify-items: center;
    align-items: center;
    margin-bottom: 60px;
    justify-content: center;
  }

  .titel {
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    text-align: center;
    margin-top: 150px;
  }

  .wintertarief {
    height: 532px;
    width: 350px;
    background-color: #ffffff;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.2);
    padding: 0px 40px;
    margin-right: 50px;
    & p {
      font-size: 16px;
      color: #14284e;
      text-align: center;
      line-height: 35px;
      margin-top: 30px;
    }
  }

  .seizoenen {
    font-size: 25px;
    color: #14284e;
    font-weight: 700;
    margin-top: 30px;
  }

  h2 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
  }

  h3 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
    margin: 25px 0px;
  }

  .zomertarief {
    height: 532px;
    width: 350px;
    background-color: #ffffff;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.2);
    padding: 0px 40px;
    margin-left: 50px;
    & p {
      font-size: 16px;
      color: #14284e;
      text-align: center;
      line-height: 35px;
      margin-top: 30px;
      margin-bottom: 35px;
    }
  }

  h4 {
    font-size: 35px;
    color: #14284e;
    font-weight: 700;
    text-align: center;
    margin: 35px 0px;
  }

  .grid-container-kosten p {
    color: #14284e;
    line-height: 30px;
    width: 475px;
    font-weight: 16px;
  }

  span {
    font-weight: 700;
    font-size: 18px;
  }

  img {
    height: 330px;
    width: 500px;
  }

  @media (max-width: 1200px) {
    .wintertarief {
      margin-right: 0px;
    }

    .zomertarief {
      margin-left: 0px;
    }

    .grid-container-tarieven,
    .grid-container-kosten {
      grid-gap: 0px;
    }

    .grid-container-kosten {
      p {
        width: 400px;
      }
    }

    .grid-container-kosten img {
      width: 400px;
      height: auto;
    }
  }

  @media (max-width: 997px) {
    .grid-container-tarieven,
    .grid-container-kosten {
      grid-template-columns: 500px;
      height: auto;
      width: auto;
      align-content: center;
      grid-gap: 50px 0px;
    }

    .wintertarief {
      margin-top: 50px;
    }

    .zomertarief {
      margin-bottom: 50px;
    }

    @media (max-width: 500px) {
      h4 {
        font-size: 25px;
      }
      .titel {
        font-size: 50px;
      }
      .wintertarief {
        height: 375px;
        width: 220px;
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 14px;
        }
        .seizoenen {
          font-size: 20px;
        }
      }
      .zomertarief {
        height: 410px;
        width: 220px;
        h3 {
          font-size: 25px;
        }
        p {
          font-size: 14px;
        }
        .seizoenen {
          font-size: 20px;
        }
      }
    }
    .grid-container-kosten {
      p {
        width: 300px;
        font-size: 14px !important;
      }
      img {
        width: 300px;
        height: 250px;
      }
    }
  }
`;

const Tarieven = () => (
  <Layout>
    <BodyStyles>
      <div className="main">
        <div className="grid-container-title">
          <h1 className="titel">Tarieven</h1>
        </div>
        <div className="grid-container-tarieven">
          <div className="wintertarief">
            <h4>Winter 2025</h4>
            <hr />
            <p>
              <span className="seizoenen">Hoogseizoen &euro;1430,-</span>
              <br />
              21/12/2024 tot 08/03/2025
            </p>
            <p>
              <span className="seizoenen">Laagseizoen &euro;1300,-</span>
              <br />
              08/03/2025 tot 29/03/2025
              <br />
              Schoonmaak &euro;85,-
              <br />
              Beddengoed &euro;10,- pp
            </p>
          </div>
          <div className="zomertarief">
            <h4>Zomer 2025</h4>
            <hr />
            <p>
              <span className="seizoenen">Hoogseizoen &euro;930,-</span>
              <br />
              26/04/2025 tot 10/05/2025
              <br />
              05/07/2025 tot 30/08/2025
            </p>
            <p>
              <span className="seizoenen">Laagseizoen &euro;785,-</span>
              <br />
              29/03/2025 tot 26/04/2025
              <br />
              11/05/2025 tot 05/07/2025
              <br />
              30/08/2025 tot 29/11/2025
              <br />
              Schoonmaak &euro;85,-
              <br />
              Bedden &euro;10,- pp
            </p>
          </div>
        </div>
        <h4>Bijkomende kosten</h4>
        <div className="grid-container-kosten">
          <p>
            <span>Verplicht</span>
            <br />
            Het schoonmaken van het appartement (&euro;85,-) Toeristenbelasting
            (Per persoon per nacht &euro;3,50)
            <br />
            <span>Optioneel</span>
            <br />
            Beddengoed (&euro;10,- p.p)
            <br />
            1 persoonsbed (&euro;10,-) 2 persoonsbed (&euro;20,-)
            <br />
            <br />
            Wij vragen een borg van 400 euro die u weer terug krijgt bij het
            netjes achterlaten van ons appartement en het meewerken aan de
            afvalscheiding in Oostenrijk.
          </p>
          <img draggable="false" src={kosten} alt="" />
        </div>
      </div>
    </BodyStyles>
  </Layout>
);

export default Tarieven;
